import * as React from 'react';

import { signInWithGoogle } from '../../services/firebase';

import Button from '@mui/material/Button';

const Login = () => {
	return (
		<div>
			<Button className='button' onClick={signInWithGoogle}>
				<i className='fab fa-google'></i>Sign in with google
			</Button>
		</div>
	);
};

export default Login;
