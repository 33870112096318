import React from 'react';

import { auth } from '../../services/firebase';

import '../../App.css';
import { Avatar, Button, Card, CardContent, CardHeader, Container } from '@mui/material';

const Home = ({ user }) => {
	return (
		<Container>
			<Card sx={{ maxWidth: 345 }}>
				<CardHeader
					avatar={<Avatar alt={user.displayName} src={user.photoURL} sx={{ width: 56, height: 56 }} referrerPolicy='no-referrer' />}
					title={'Hello, ' + user.displayName}
				/>
				<CardContent>
					<Button className='button signout' onClick={() => auth.signOut()}>
						Sign out
					</Button>
				</CardContent>
			</Card>
		</Container>
	);
};

export default Home;
