import { useState, useEffect } from 'react';

import Login from './routes/Login';
import Home from './routes/Home';
import { auth } from './services/firebase';

import './App.css';

function App() {
	const [user, setUser] = useState(null);

	useEffect(() => {
		auth.onAuthStateChanged(user => {
			setUser(user);
		});
	}, []);

	console.log(user);

	return <div className='app'>{user ? <Home user={user} /> : <Login />}</div>;
}

export default App;
