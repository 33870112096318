import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

const firebaseConfig = {
	apiKey: 'AIzaSyCxfYfu9seByvA6KRYdvcZWhLFVsl2k7cM',
	authDomain: 'mrherndon-c356b.firebaseapp.com',
	databaseURL: 'https://mrherndon-c356b.firebaseio.com',
	projectId: 'mrherndon-c356b',
	storageBucket: 'mrherndon-c356b.appspot.com',
	messagingSenderId: '773633102914',
	appId: '1:773633102914:web:40e3c19075d87e79bb49db',
	measurementId: 'G-JSMLEL8K0T',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

const provider = new GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'select_account' });

export const signInWithGoogle = () => signInWithPopup(auth, provider);
